<template>
  <div class="activity_home">
  
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in hd.banner" :key="index">
        <img v-lazy="image" width="100%"/>
      </van-swipe-item>
    </van-swipe>

    <div class="container">
      <div class="countDown">
        <van-count-down :time="time">
          <template #default="timeData">
            <span v-if="hd.state==2">活动已结束</span>
            <template v-else>
              <span>距{{hd.state==0?'开始':'结束'}}：</span>
              <span class="block">{{ timeData.days }}</span>
              <span class="colon">天</span>
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">时</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">分</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">秒</span>
            </template>
          </template>
        </van-count-down>
      </div>
      
      <van-row class="user_statistics">
        <van-col span="8" class="item">
          <label>{{hd.total_user}}</label>
          <span>已参与</span>
        </van-col>
        <van-col span="8" class="item">
          <label>{{hd.seckill_tj_num}}</label>
          <span>已购买</span>
        </van-col>
        <van-col span="8" class="item">
          <label>{{hd.amount}}</label>
          <span>已阅读</span>
        </van-col>
      </van-row>

      <goodList :order_type="2" :dataList="goodList" :data="hd" v-if="goodList.length"/>
      
      <div class="column">
        <div class="h4">他们很感兴趣</div>
      </div>
      <div class="index_box">
        <userList :hdid="hd.id"/>
      </div>
      
      <template v-if="hd.seckill_tj_num>0">
      <div class="column">
        <div class="h4">已有 <em>{{hd.seckill_tj_num}}</em> 人购买</div>
      </div>
      <div class="index_box">
        <orderuser :hdid="hd.id"/>
      </div>
      </template>
      
      <template v-if="hd.content != null && hd.content != ''">
      <div class="column">
        <div class="h4">活动介绍</div>
      </div>
      <div class="index_box">
        <div class="textContent" v-html="hd.content"></div>
      </div>
      </template>
      
      <div class="mt10"><shopInfo :data="hd.shopInfo"/></div>
    
    </div>
  </div>
</template>

<script>
  import shopInfo from '@/pages/wx/components/shopInfo'
  import goodList from '@/pages/wx/components/goodList'
  import userList from '@/pages/wx/components/userList'
  export default {
    props: {
      hd:Object,
      goodList:Array
    },
    components: {goodList,userList,shopInfo},
    data() {
      return {
        time:0
      }
    },
    activated() {
      this.setBgColor()
    },
    created(){
      this.setBgColor()
    },
    mounted() {
      this.time = new Date(this.hd.end_time).getTime() - new Date().getTime();
    },
    methods: {
      setBgColor(){
        if(this.hd.bgcolor==null || this.hd.bgcolor==''){
          document.querySelector('body').setAttribute('style', 'background-color:#c8f0db')
        }
      },
    }
  }
</script>
<style lang="less">
  .activity_home{
    .container{padding:10px;}
    .column{height:51px;text-align:center;background:url('../image/template4_lmbg.png') no-repeat;background-size:100% auto;width:70%;margin:auto;
      .h4{color:#fff;font-size:16px;font-weight:700;line-height:36px;}
    }
  
    .countDown{border-radius:4px;text-align:center;border-top:4px solid #299c5f;position:relative;padding-top:26px;
      &:before,&:after{content:'';width:2px;height:26px;position:absolute;top:0px;z-index:1;background:#299c5f;}
      &:before{left:20px;}
      &:after{right:20px;}
      .van-count-down{border:1px dashed #299c5f;border-radius:4px;padding:14px 0px;color:#299c5f;
        .block{background:#299c5f;}
      }
    }
  
    .user_statistics{background:#fff;border-radius:4px;padding:15px 0px;height:auto;margin-top:10px;
      .item{text-align:center;
        label{color:#299c5f;font-size:20px;width:100%;display:inline-block;font-weight:700;}
        span{width:100%;display:inline-block;margin-top:5px;color:#666;}
      }
    }
    .goodList{
      .item{
        .action{
          .van-button--danger{background:#218946;border:1px solid #218946;}
        }
      }
    }
  
    .index_box{border-radius:4px;background:#fff;padding:10px;}
  }
  
  .footerAction{
    .btn1{background:#ff7330;border:1px solid #ff7330;}
    .btn2{background:#218946;border:1px solid #218946;}
  }
</style>
